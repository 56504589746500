import mapMoveDeal from '@/views/users/notifications/Mappers/map-move-deal';
import addResponsibleDeal from '@/views/users/notifications/Mappers/add-responsible-deal';
import mapMoveArchive from '@/views/users/notifications/Mappers/map-move-archive';
import changeDateDeal from '@/views/users/notifications/Mappers/change-date-deal';
import closedDeal from '@/views/users/notifications/Mappers/closed-deal';
import changeDescription from '@/views/users/notifications/Mappers/change-description';
import actionCoverDeal from '@/views/users/notifications/Mappers/action-cover-deal';
import actionNote from '@/views/users/notifications/Mappers/action-note';
import actionLabel from '@/views/users/notifications/Mappers/action-label';
import addExecutorDeal from '@/views/users/notifications/Mappers/add-executor-deal';
import detachResponsibleDeal from '@/views/users/notifications/Mappers/detach-responsible-deal';
import detachExecutorDeal from '@/views/users/notifications/Mappers/detach-executor-deal';
import actionCheckList from '@/views/users/notifications/Mappers/action-check-list';
import actionCheckListItem from '@/views/users/notifications/Mappers/action-check-list-item';
import overdueDeal from '@/views/users/notifications/Mappers/overdueDeal';
import soonDeal from '@/views/users/notifications/Mappers/soon-deal';
import mentionNote from '@/views/users/notifications/Mappers/mention-note';
import createLead from '@/views/users/notifications/Mappers/create-lead';
import attachUserPipeline from '@/views/users/notifications/Mappers/attach-user-pipeline';
import detachUserPipeline from '@/views/users/notifications/Mappers/detach-user-pipeline';
import answerNote from '@/views/users/notifications/Mappers/answer-note';
import attachResponsibleCheckListItem from '@/views/users/notifications/Mappers/attach-responsible-check-list-item';
import detachResponsibleCheckListItem from '@/views/users/notifications/Mappers/detach-responsible-check-list-item';
import checkListOverdue from '@/views/users/notifications/Mappers/check-list-overdue';
import checklistItemOverdue from '@/views/users/notifications/Mappers/checklist-item-overdue';

export default function(notifications) {
    const mappers = {
        move_deal: mapMoveDeal,
        add_responsible_deal: addResponsibleDeal,
        detach_responsible_deal: detachResponsibleDeal,
        add_executor_deal: addExecutorDeal,
        move_deal_archive: mapMoveArchive,
        action_date_deal: changeDateDeal,
        action_is_close_deal: closedDeal,
        action_description_deal: changeDescription,
        action_cover_deal: actionCoverDeal,
        action_deal_note: actionNote,
        action_label_deal: actionLabel,
        detach_executor_deal: detachExecutorDeal,
        created_check_list: actionCheckList,
        deleted_check_list: actionCheckList,
        changed_check_list: actionCheckList,
        changed_execution_date_check_list: actionCheckList,
        complete_check_list: actionCheckList,
        created_check_list_item: actionCheckListItem,
        changed_check_list_item: actionCheckListItem,
        changed_execution_date_check_list_item: actionCheckListItem,
        deleted_check_list_item: actionCheckListItem,
        changed_active_item: actionCheckListItem,
        overdue_deal: overdueDeal,
        soon_deal: soonDeal,
        mention_user_in_note: mentionNote,
        create_lead: createLead,
        attach_user_pipeline: attachUserPipeline,
        detach_user_pipeline: detachUserPipeline,
        answer_note: answerNote,
        attach_responsible_check_list_item: attachResponsibleCheckListItem,
        detach_responsible_check_list_item: detachResponsibleCheckListItem,
        check_list_overdue: checkListOverdue,
        check_list_soon: checkListOverdue,
        check_list_item_overdue: checklistItemOverdue,
        check_list_item_soon: checklistItemOverdue,
    };
    return mappers[notifications.type](notifications);
}
